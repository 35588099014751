
  import { computed, defineComponent, ref, useContext, watch } from "@nuxtjs/composition-api";
  import useNavigation, { MenuItem } from "../../use/useNavigation";

  export default defineComponent({
    name: "NavigationDrawerV2",

    setup() {
      const context = useContext();
      const { menuItems } = useNavigation(context);
      const { route } = context;

      const openGroups = ref<{ [key: string]: boolean }>({});

      const isActive = (item: MenuItem) => {
        if (item.activePaths && item.activePaths.length > 0) {
          return item.activePaths.some((path) =>
            route.value.path.includes(path)
          );
        }
        return item.to ? route.value.path.startsWith(item.to) : false;
      };

      const activeGroupKeys = computed(() =>
        menuItems.value
          .filter((item) => item.children && item.children.some((sub) => isActive(sub)))
          .map((item) => item.title)
      );

      watch(
        activeGroupKeys,
        (newActiveGroups) => {
          openGroups.value = menuItems.value.reduce((acc, item) => {
            // eslint-disable-next-line no-param-reassign
            acc[item.title] = newActiveGroups.includes(item.title);
            return acc;
          }, {} as { [key: string]: boolean });
        },
        { immediate: true }
      );

      const year = new Date().getFullYear();
      const shortYear = year.toString().slice(-2);

      return {
        menuItems,
        shortYear,
        isActive,
        openGroups,
      };
    }
  });
