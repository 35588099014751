import { render, staticRenderFns } from "./InternetTradingPolicyDetailsPopUp.vue?vue&type=template&id=5490f3ac&scoped=true&lang=pug"
import script from "./InternetTradingPolicyDetailsPopUp.vue?vue&type=script&lang=ts"
export * from "./InternetTradingPolicyDetailsPopUp.vue?vue&type=script&lang=ts"
import style0 from "./InternetTradingPolicyDetailsPopUp.vue?vue&type=style&index=0&id=5490f3ac&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5490f3ac",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {InternetTradingPolicy: require('/workspace/portal/hosting/components/InternetTradingPolicy.vue').default,LsButton: require('/workspace/portal/hosting/components/_base/Button/Button.vue').default})
