
  import {
    computed,
    defineComponent,
    ref,
    useContext,
    useRouter
  } from "@nuxtjs/composition-api";
  import { useCurrentUser } from "../use/useFireAuth";
  import { useInternetTradingPolicyInject } from "../use/useInternetTradingPolicy";
  import useAuthStore from "../stores/auth/auth.store";

  export default defineComponent({
    name: "InternetTradingPolicyPopUp",

    setup() {
      const context = useContext();
      const router = useRouter();
      const { route } = context;
      const {
        showITP,
        acceptITP,
        loading,
        viewITPDetails,
        showITPDetails,
        closeITPErrorDetails,
        showITPError,
        emailOverride
      } = useInternetTradingPolicyInject(context);
      const authStore = useAuthStore();
      const { logout } = useCurrentUser(context);

      const checkbox = ref(false);

      const onLogout = () => {
        logout();
        authStore.resetAuthState();
        if (route.value.path !== "/") {
          router.push("/");
        }
      };

      const closePopup = () => {
        if (!emailOverride.value) {
          onLogout();
        } else {
          showITPError.value = true;
        }
      };

      const textConfig = {
        default: {
          title: 'Internet Trading Policy',
          body: ['Thanks for being a part of Loadsure. We need to make sure you agree to our latest platform policies.'],
        },
        onboarding: {
          title: 'Welcome to Loadsure!',
          body: [
            'You’re ready to secure instant, all-risk cargo insurance for your next shipment! 🎉',
            'Before you get started, we just need you to review and agree to our latest platform policies.'
          ],
        },
      }
      const texts = computed(() => {
        const tradingPolicyModal = route.value.query['trading-policy-modal'] as string;
        return textConfig[tradingPolicyModal] || textConfig.default;
      });

      return {
        showITP,
        showITPDetails,
        checkbox,
        viewITPDetails,
        acceptITP,
        loading,
        closePopup,
        showITPError,
        closeITPErrorDetails,
        texts,
      };
    }
  });
