
  import {
    PropType,
    Ref,
    computed,
    defineComponent,
    onMounted,
    ref
  } from "@nuxtjs/composition-api";
  import { useSiteConfigurationInject } from "../use/useSiteConfiguration";

  const partnerLogos = {
    "LS-DMO": { src: "/logos/partners/LS-DMO.webp", height: 144, width: 143 },
    "LS-3PL": { src: "/logos/partners/LS-3PL.png", height: 184, width: 253 },
    "LS-DAT": { src: "/logos/partners/LS-DAT.svg", height: 44, width: 140 },
    "LS-TTS": { src: "/logos/partners/LS-TTS.png", height: 44, width: 250 }
  };

  const sizeHeights = {
    small: 14,
    medium: 20,
    large: 30
  };

  const plusSizeProps = {
    small: { dense: true },
    medium: {},
    large: { large: true }
  };

  type sizes = "small" | "medium" | "large";

  function adjustedSize(
    { height: originalHeight, width: originalWidth },
    size: sizes
  ) {
    let height = sizeHeights[size];
    let width = (originalWidth / originalHeight) * height;
    const aspectRatio = width / height;
    if (aspectRatio < 6) {
      width *= 1.4;
      height *= 1.4;
    }
    return { height, width };
  }

  interface iImage {
    src: string;
    height: number;
    width: number;
  }

  function adjustedLogo(logo: iImage, size: sizes) {
    return {
      ...logo,
      ...adjustedSize(logo, size)
    };
  }

  function getLoadsureLogo(darkMode: boolean) {
    if (darkMode) {
      return {
        src: "/logos/loadsure/logo-white.png",
        height: 85,
        width: 632
      };
    }
    return {
      src: "/logos/loadsure/logo-black.png",
      height: 85,
      width: 632
    };
  }

  export default defineComponent({
    name: "LogoPortal",

    props: {
      partnerId: {
        type: String,
        default: undefined
      },
      size: {
        type: String as PropType<sizes>,
        default: "medium"
      },
      darkMode: {
        type: Boolean,
        default: false
      },
      isHeader: {
        type: Boolean,
        default: false
      },
      customWidth: {
        type: Number,
        default: undefined
      }
    },

    emits: ["isPartnerLogo"],

    setup(props, { emit }) {
      const { getLogo } = useSiteConfigurationInject();

      const partnerLogoRef: Ref<iImage | undefined> = computed(() => {
        if (props.partnerId) {
          return partnerLogos[props.partnerId];
        }
        return getLogo("dark");
      });

      ref(undefined);

      const partnerLogo = computed(() =>
        partnerLogoRef.value
          ? adjustedLogo(partnerLogoRef.value, props.size)
          : undefined
      );

      const loadsureLogo = ref(
        adjustedLogo(getLoadsureLogo(props.darkMode), props.size)
      );

      if (props.customWidth)
        loadsureLogo.value = {
          ...loadsureLogo.value,
          width: props.customWidth
        };

      const plusSizeProp = computed(() => {
        const color = props.darkMode ? "white" : "black";
        return { ...plusSizeProps[props.size], color };
      });

      onMounted(() => {
        emit("isPartnerLogo", partnerLogo);
      });

      return {
        partnerLogo,
        loadsureLogo,
        plusSizeProp
      };
    }
  });
