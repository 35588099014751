import { render, staticRenderFns } from "./Loading.vue?vue&type=template&id=06cdee2d&scoped=true&lang=pug"
import script from "./Loading.vue?vue&type=script&lang=ts"
export * from "./Loading.vue?vue&type=script&lang=ts"
import style0 from "./Loading.vue?vue&type=style&index=0&id=06cdee2d&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "06cdee2d",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {AnimatedLogo: require('/workspace/portal/hosting/components/animated/animatedLogo.vue').default})
